<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  import CopyClipBoard from "../CopyClipBoard.svelte";
  //const currentYear = new Date().getFullYear();
  let tab = "intro";
  let viewMenu = false;
  onMount(async () => {});

  const copy = () => {
    const clipboardTxt = "http://api.qix.cloud";
    const app = new CopyClipBoard({
      target: document.getElementById("clipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    alert("Copied.");
  };
</script>

<div class="container">
  <div class="tab-wrap">
    <div class="tab-header flex items-center">
      <div
        class="tab"
        class:act={tab == "intro"}
        on:click={() => (tab = "intro")}
      >
        Intro
      </div>
      <div
        class="tab"
        class:act={tab == "urls"}
        on:click={() => (tab = "urls")}
      >
        Base Urls
      </div>
      <div
        class="tab"
        class:act={tab == "authentication"}
        on:click={() => (tab = "authentication")}
      >
        Authentication
      </div>
    </div>
  </div>
  <div class="body">
    {#if tab == "urls"}
      <h4>Base Url</h4>
      <p>
        The Base Url contains all Chat API endpoints.
      </p>
      <div id="clipboard" />
      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        <span style="width: calc(100% - 50px); word-break: break-all;"
          >http://api.qix.cloud</span
        ><img
          src="/content_copy.png"
          class="cursor-pointer"
          style="width: 35px;"
          on:click={copy}
        />
      </p>

    {:else if tab == "authentication"}
      <h4>Authentication</h4>
      <p>
        JWT <br>
        Chat API uses the JWT system to access user and conversation data. 
        Without the JWT Token, you can not access any data. 
        You can get JWT Token with endpoint <b>POST https://api.qix.cloud/sign/phone</b>.
        The endpoint requires "phone" (+12345...), "app" (appId), and "code" (2fa code).
        After getting it, the JWT should be saved somewhere locally on the user's device (localStorage).
        <br>
        <br>
        Code
        <br>
        Before using the endpoint you must send 2fa code to your user by endpoint <b>POST https://api.qix.cloud/sendCode</b>.
        The endpoint requires a "phone". After Sending the code, your user will enter it, and you can 
        call https://api.qix.cloud/sign/phone endpoint.
        <br>
        <br>
        App Id
        <br>
        Each app has its own id. You can get it in settings in the Chat App (https://qix.cloud/chat)
      </p>
      <p class="p-5" style="background: #edf2f6;border-radius: 10px;">
        Example AppId: 3Ymf2mkoySyEvca8d9FA
      </p>
      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        Jump to Chat App<a
          href="https://qix.cloud/chat"
          target="_blank"
          ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
        >
      </p>
    {:else}
      <h4>Welcome to the Chat API!</h4>
      <p>
        Chat API is a powerful tool for chatting. 
        Each client has a conversation, which can be accessed using a phone number with 2-factor authentication. 
        Every conversation has a lot of different information you can use and edit, for example, Events, Party Info, Case Matters, etc. 
        All of the information can be used for giving users the best experience.
      </p>
    {/if}
  </div>
</div>

<style>
  input[type="text"],
  textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .tab-wrap {
    overflow: auto;
    padding-bottom: 10px;
  }
  .tab-header {
    margin-top: 15px;
    flex-wrap: wrap;
    min-width: 780px;
  }
  .tab-header .tab {
    font-size: 1em;
    padding: 2px 12px;
    border-radius: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  .tab-header .tab.act {
    background: #d2e9f5;
  }

  .tab-wrap::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  .tab-wrap::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .tab-wrap::-webkit-scrollbar-thumb {
    background: #d2e9f5;
    border-radius: 5px;
  }

  /* Handle on hover */
  .tab-wrap::-webkit-scrollbar-thumb:hover {
    background: #d2e9f5;
  }
  .body h4 {
    font-weight: bold;
    padding: 15px 0 5px 0;
    font-size: 1.1rem;
  }
  .body p {
    margin-bottom: 10px;
  }
</style>
