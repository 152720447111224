<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import ContentApp from "./ContentApp.svelte";
  import ContentChat from "./ContentChat.svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  //const currentYear = new Date().getFullYear();
  let firstLoading = false;
  let tab = "app";
  onMount(async () => {
    setTimeout(() => {
      firstLoading = true;
    }, 1000);
  });
  function onHide() {
    dispatch("hide");
  }
</script>

{#if firstLoading}
  <div class="wrap container">
    <div class="child-header flex">
      <div
        class="tab flex items-center"
        class:act={tab == "app"}
        on:click={() => (tab = "app")}
      >
        <img src="/Mobile_App_logo_256.png" /> App
      </div>
      <div
        class="tab flex items-center"
        class:act={tab == "chat"}
        on:click={() => (tab = "chat")}
      >
        <img src="/qix_chat_logo.png" /> Chat
      </div>
    </div>
    {#if tab == "app"}
      <ContentApp {user_data} />
    {/if}
    {#if tab == "chat"}
      <ContentChat {user_data} />
    {/if}
  </div>
{:else}
  <div
    class="w-full flex-grow flex justify-center items-center"
    style="height: 100vh;position: fixed;background: #fff;top: 0;left: 0;z-index: 99;"
  >
    <img src="/EmoteHi.gif" />
  </div>
{/if}

<style>
  input[type="text"],
  textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .wrap {
    position: relative;
    background: #fff;
    padding: 20px 30px;
    border-radius: 15px;
    max-width: 768px;
    /* border: 3px dashed #f1f3f6; */
  }
  .wrap .child-header {
    margin-bottom: 20px;
  }
  .wrap .child-header .tab {
    font-size: 1.2em;
    padding: 8px 16px;
    border-radius: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .wrap .child-header .tab.act {
    background: #d2e9f5;
  }
  .wrap .child-header .tab img {
    width: 30px;
    margin-right: 10px;
  }
</style>
