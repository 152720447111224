<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  import CopyClipBoard from "../CopyClipBoard.svelte";
  //const currentYear = new Date().getFullYear();
  let tab = "intro";
  let viewMenu = false;
  onMount(async () => {});

  const copy = () => {
    const clipboardTxt = "https://qix.cloud/ajax/app.php";
    const app = new CopyClipBoard({
      target: document.getElementById("clipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    alert("Copied.");
  };
</script>

<div class="container">
  <div class="tab-wrap">
    <div class="tab-header flex items-center">
      <div
        class="tab"
        class:act={tab == "intro"}
        on:click={() => (tab = "intro")}
      >
        Intro
      </div>
      <div
        class="tab"
        class:act={tab == "urls"}
        on:click={() => (tab = "urls")}
      >
        Base Urls
      </div>
      <!-- <div
        class="tab"
        class:act={tab == "tokens"}
        on:click={() => (tab = "tokens")}
      >
        Users and Tokens
      </div> -->
      <div
        class="tab"
        class:act={tab == "authentication"}
        on:click={() => (tab = "authentication")}
      >
        Authentication
      </div>
      <div
        class="tab"
        class:act={tab == "field"}
        on:click={() => (tab = "field")}
      >
        Object Selection
      </div>
      <div
        class="tab"
        class:act={tab == "pagination"}
        on:click={() => (tab = "pagination")}
      >
        Pagination
      </div>
    </div>
  </div>
  <div class="body">
    {#if tab == "urls"}
      <h4>Base Urls</h4>
      <p>The Base Url contains all App Settings endpoints.</p>
      <div id="clipboard" />
      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        <span style="width: calc(100% - 50px); word-break: break-all;"
          >https://qix.cloud/ajax/app.php</span
        ><img
          src="/content_copy.png"
          class="cursor-pointer"
          style="width: 35px;"
          on:click={copy}
        />
      </p>
    {:else if tab == "tokens"}
      <h4>Users and Tokens</h4>
      <p>
        Tokens are used in APIs to provide secure authentication and
        authorization for accessing protected resources. They serve as
        credentials that verify the identity of the user or application making
        API requests. In the context of an App, each user is assigned a unique
        "App Token" generated in the App settings page, specifically under the
        "General" section and the "Tokens" subsection. This App Token acts as a
        form of authentication and allows the API to verify the user's identity
        and permissions without requiring sensitive information like usernames
        and passwords to be exchanged with every request. By using tokens, API
        providers can enhance security, control access to resources, and easily
        manage and revoke access for individual users or applications.
      </p>
      <p class="p-5" style="background: #edf2f6;border-radius: 10px;">
        Example Token: ukBwTty0cGvQ
      </p>

      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        Jump to App Settings Page <a
          href="https://legalpal.app/app"
          target="_blank"
          ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
        >
      </p>
    {:else if tab == "authentication"}
      <h4>Authentication</h4>
      <p>
        JWT <br />
        Chat API uses the JWT system to access user and conversation data. Without
        the JWT Token, you can not access any data. You can get JWT Token with endpoint
        <b>POST https://api.qix.cloud/sign/phone</b>. The endpoint requires
        "phone" (+12345...), "app" (appId), and "code" (2fa code). After getting
        it, the JWT should be saved somewhere locally on the user's device
        (localStorage).
        <br />
        <br />
        Code
        <br />
        Before using the endpoint you must send 2fa code to your user by endpoint
        <b>POST https://api.qix.cloud/sendCode</b>. The endpoint requires a
        "phone". After Sending the code, your user will enter it, and you can
        call https://api.qix.cloud/sign/phone endpoint.
        <br />
        <br />
        App Id
        <br />
        Each app has its own id. You can get it in settings in the Chat App (https://qix.cloud/chat)
      </p>
      <p class="p-5" style="background: #edf2f6;border-radius: 10px;">
        Example AppId: 3Ymf2mkoySyEvca8d9FA
      </p>
      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        Jump to Chat App<a href="https://qix.cloud/chat" target="_blank"
          ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
        >
      </p>
    {:else if tab == "field"}
      <h4>Object Selection</h4>
      <p>
        The Object selection allows clients to specify which specific parts or
        properties of a JSON response they want to retrieve. For instance,
        consider an API endpoint that returns a JSON file containing various
        data. To extract a specific section of the JSON file, clients can
        utilize Object selection syntax like "appSettings.sliders" in their
        request.
      </p>
      <p class="p-5" style="background: #edf2f6;border-radius: 10px;">
        Example: sliders = appSettings.sliders
      </p>
      <p
        class="p-5 flex items-center"
        style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
      >
        Jump to "Github" for Sample Code <a
          href="https://github.com/qixcloud/Testing/blob/main/Auth.js"
          target="_blank"
          ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
        >
      </p>
    {:else if tab == "pagination"}
      <h4>Pagination</h4>
    {:else}
      <h4>Welcome to the App Settings API!</h4>
      <p>
        a powerful tool designed to enhance your application's content delivery
        network (CDN) and ensure a seamless end-user experience. Our API opens
        up a world of possibilities, allowing you to leverage the CDN for your
        mobile app and other front-end use cases, delivering consistent and
        engaging content across multiple platforms.
      </p>
      <p>
        With our API, you gain access to a range of essential settings and
        configurations. Translated content, image content, and notification
        tracking are just a few examples of what you can manage effortlessly. By
        centralizing these settings, you can maintain brand consistency and
        deliver personalized experiences to your users.
      </p>
    {/if}
  </div>
</div>

<style>
  input[type="text"],
  textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .tab-wrap {
    overflow: auto;
    padding-bottom: 10px;
  }
  .tab-header {
    margin-top: 15px;
    flex-wrap: wrap;
    min-width: 780px;
  }
  .tab-header .tab {
    font-size: 1em;
    padding: 2px 12px;
    border-radius: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  .tab-header .tab.act {
    background: #d2e9f5;
  }

  .tab-wrap::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  .tab-wrap::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .tab-wrap::-webkit-scrollbar-thumb {
    background: #d2e9f5;
    border-radius: 5px;
  }

  /* Handle on hover */
  .tab-wrap::-webkit-scrollbar-thumb:hover {
    background: #d2e9f5;
  }
  .body h4 {
    font-weight: bold;
    padding: 15px 0 5px 0;
    font-size: 1.1rem;
  }
  .body p {
    margin-bottom: 10px;
  }
</style>
