<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <h4>Scripts</h4>
    <p>Start testing the API with sample scripts</p>
    <a
      href="https://github.com/qixcloud/api-samples/tree/main"
      target="_blank"
    >
      <div class="flex items-center cursor-pointer link-wrap">
        <div>Sample Scripts</div>
      </div>
    </a>
  </div>
</div>

<style>
  .body h4 {
    font-weight: bold;
    padding: 15px 0 5px 0;
    font-size: 1.1rem;
  }
  .body {
    margin-top: 30px;
  }
  .body p {
    margin-bottom: 10px;
  }
  .link-wrap {
    background: #dee3fe;
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 360px;
    justify-content: space-between;
  }
</style>
