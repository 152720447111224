<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  export let user_data;
  import { clickOutside } from "./utils/clickOutside.js";
  import Overview from "./chat/Overview.svelte";
  import Endpoints from "./chat/Endpoints.svelte";
  import Objects from "./chat/Objects.svelte";
  import Github from "./chat/Github.svelte";
  import Billing from "./chat/Billing.svelte";
  const dispatch = createEventDispatcher();
  //const currentYear = new Date().getFullYear();
  let menu = "Overview";
  let viewMenu = false;
  onMount(async () => {});
  function onHide() {
    dispatch("hide");
  }
  function updateMenu(item) {
    menu = item;
    viewMenu = false;
  }

  function handleClickOutsideHideMenu() {
    viewMenu = false;
  }
</script>

<div class="container">
  <div
    class="menu"
    use:clickOutside
    on:click_outside={handleClickOutsideHideMenu}
  >
    <div
      class="menu-header flex items-center"
      on:click={() => (viewMenu = true)}
    >
      {menu}
      <img src="/menu.png" />
    </div>
    {#if viewMenu}
      <div class="menu-items">
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Overview")}
        >
          Overview
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Endpoints")}
        >
          Endpoints
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Objects")}
        >
          Objects
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Github")}
        >
          Github
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Billing")}
        >
          Billing
        </div>
      </div>
    {/if}
  </div>
  <div class="body">
    {#if menu == "Overview"}
      <Overview {user_data} />
    {:else if menu == "Endpoints"}
      <Endpoints {user_data} />
    {:else if menu == "Objects"}
      <Objects {user_data} />
    {:else if menu == "Github"}
      <Github {user_data} />
    {:else if menu == "Billing"}
      <Billing {user_data} />
    {/if}
  </div>
</div>

<style>
  input[type="text"],
  textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .menu {
    position: relative;
    width: 150px;
    border-radius: 5px;
  }
  .menu .menu-header,
  .menu .menu-items .item {
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
    background: #cfe6f4;
  }
  .menu .menu-header,
  .menu .menu-items {
    border-radius: 5px;
    overflow: hidden;
  }
  .menu .menu-header:hover,
  .menu .menu-items .item:hover {
    cursor: pointer;
    background: #6ec4f3;
  }
  .menu .menu-items {
    position: absolute;
    width: 100%;
  }
</style>
