<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div style="padding: 50px 0;">Coming Soon</div>
</div>

<style>
</style>
