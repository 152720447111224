<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  import CopyClipBoard from "../CopyClipBoard.svelte";
  //const currentYear = new Date().getFullYear();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <h4>API Endpoint</h4>
    <p>
      The App API endpoint, located at https://qix.cloud/ajax/app.php, plays a
      crucial role in retrieving important data for the application. When making
      a request to this endpoint, the server responds with a JSON (JavaScript
      Object Notation) object containing various settings and information
      relevant to the application. To effectively utilize the data contained
      within the JSON response, it needs to be parsed.
    </p>
    <p>
      The request is sent with the help of the axios library, specifying the
      HTTP method as "post" and the URL as the App API endpoint. Within the
      callback function, the JSON data is accessed through the "res.data"
      property and assigned to the "appSettings" variable. This allows
      developers to utilize the retrieved data in their application accordingly,
      making it accessible for further processing or displaying to users.
    </p>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Jump to "Github" for Sample Code <a
        href="https://github.com/qixcloud/Testing/blob/main/Auth.js"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
  </div>
</div>

<style>
  .body {
    margin-top: 30px;
  }
  .body p {
    margin-bottom: 10px;
  }
  .body h4 {
    font-weight: bold;
    padding: 15px 0 5px 0;
    font-size: 1.1rem;
  }
</style>
